import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { RESULT_FEATURES } from 'configs/AppFeatureConfig';
import { icons } from 'utilities';

class PricingModelRelatedInfo extends Component {
	render() {
		const { policyCode } = this.props;
		if (!policyCode) {
			return null;
		}
		return (
			<div className="pricing-model-related-info">
				<div className="pricing-model-related-info-label">Related Information</div>
				<ul className="pricing-model-related-info-link-list">
					<li>
						<img src={icons.listBulletFilledCircle} alt="list bullet" />
						<a href={`http://fundresearch.fidelity.com/prospectus/annuities?product=${policyCode}`}>
							Prospectuses and Shareholder Reports
						</a>
					</li>
				</ul>
			</div>
		);
	}
}

PricingModelRelatedInfo.propTypes = {
	policyCode: PropTypes.string.isRequired,
};

export default PricingModelRelatedInfo;
